<template>
  <div>
    <div class="box" v-loading="tabLoading">
      <el-tabs v-model="form.list_type" @tab-click="handleClickTabs">
        <template v-for="item in tabs">
          <el-tab-pane :key="item.field_value" :label="item.field_text" :name="item.field_value"></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="card" style="display: flex">
      <div class="left">
        <el-button type="primary" icon="el-icon-upload2" plain @click="handleExport" :loading="exportLoading">导出</el-button>
      </div>
      <div class="line"></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="日期">
            <el-date-picker
              style="width: 280px"
              v-model="form.create_time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="门店">
            <el-select
              v-model="form.hierarchy_id"
              placeholder="请选择"
              filterable=""
              :disabled="userInfo.hierarchy_type_id === 40"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.hierarchy_id"
                :label="item.hierarchy_name"
                :value="item.hierarchy_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="仓库">
            <el-select v-model="form.warehouse_id" filterable="" clearable="">
              <template v-for="item in warehouseList">
                <el-option :value="item.warehouse_id" :label="item.warehouse_name" :key="item.warehouse_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="品类">
            <SelectTree
              :value="valueId"
              id="select"
              :props="props"
              :options="treeData"
              label1="商品分类"
              :clearable="isClearable"
              :accordion="isAccordion"
              @getValue="getValue($event)"
            />
          </el-form-item>
          <el-form-item label="材质成色">
            <el-select v-model="form.material_id" filterable="" clearable="">
              <template v-for="item in materialList">
                <el-option :value="item.material_name" :label="item.material_name" :key="item.material_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="货号">
            <el-input v-model="form.goods_number" placeholder="请输入" clearable=""></el-input>
          </el-form-item>
          <el-form-item label="款号">
            <el-input v-model="form.goods_style_number" placeholder="请输入" clearable=""></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="right" style="display: flex">
        <div class="line"></div>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
        <el-button @click="handleReset">重置</el-button>
        <!-- <el-button type="primary" @click="handleConf">列表配置</el-button> -->
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%" :row-class-name="handleIndex" v-loading="loading" height="700px" ref="dataTable">
        <template v-for="item in columns">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_widht"
            v-if="item.show"
            align="center"
            :fixed="item.fixed"
          >
            <template v-if="item.children">
              <el-table-column
                v-for="child in item.children"
                :key="child.field_alias"
                :prop="child.field_alias"
                :label="child.field_text"
                :width="child.t_widht"
                v-if="child.show"
                align="center"
                :fixed="item.fixed"
              >
                <template slot-scope="scope">
                  <span v-if="item.field_text !== '期间支出'">
                    {{ scope.row[child.field_alias] }}
                  </span>
                  <span v-else>
                    {{ scope.row[child.field_alias] ? '-' + scope.row[child.field_alias] : '' }}
                  </span>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Conf
      :dialogVisible="dialogVisible"
      :title="title"
      @onColumns="handleColumns"
      @onClose="handleClose"
      @onSuccess="handleSuccess"
      :listConf="listConf"
    />
  </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import Conf from "../components/Conf.vue";
import storage from "good-storage";
import { getMerchantListReq } from "@/api/authority/menage.js";
import numeral from 'numeral';
import { tableConfReq, getGoodsDataReq, exportReq, getColumnForMenuReq, getAllGoodsTypeReq, getMaterialListReq } from '@/api/statistics/raws.js';
import { listReq as getWarehouseListReq } from '@/api/goods/goodsStore/list';

export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      valueId: '',
      hierarchy_id: '',
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      treeData: [],
      materialList: [], // 材质成色
      allGoodsType: [], // 品类
      warehouseList: [],
      userInfo,
      tabLoading: false,
      tabs: [],
      create_time: [],
      exportLoading: false,
      loading: false,
      listConf: [], // 列表配置项
      columns: [
        {
          field_text: "序号",
          field_alias: "index",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        },
        {
          field_text: '品类',
          field_alias: "field_value",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        },
        {
          field_text: '材质成色',
          field_alias: "attr_value",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        },
        {
          field_text: "期初",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "start_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "start_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "start_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "回收",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "entry_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "entry_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "entry_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "赎回",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "redemption_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "redemption_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "redemption_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "调仓",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "allot_warehouse_to_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "allot_warehouse_to_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "allot_warehouse_to_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "调拨",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "allot_warehouse_to_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "allot_warehouse_to_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "allot_warehouse_to_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "拆改",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "split_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "split_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "split_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "旧料转金料",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "material_entry_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "material_entry_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "material_entry_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
        {
          field_text: "期末",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "end_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "重量",
              field_alias: "end_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "金额",
              field_alias: "end_data_total_recycle_price",
              t_widht: "120px",
              show: true,
              type: "total_recycle_price",
            },
          ],
        },
      ], // 表格列数据
      tableData: [], // 表单数据
      merchantList: [], // 仓库列表
      form: {
        create_time: [],
        list_type: '10',
        hierarchy_id: '',
        goods_type_id: '',
        warehouse_id: '',
        material_id: '',
      }, // 表单数据
      title: "",
      dialogVisible: false,
      page: 1,
      mode: 1,
      limit: this.$store.state.pageSizes[0],
      rowList: [],
      printRows: [],
    };
  },
  components: {
    Cond,
    Conf,
  },
  created() {
    this.getColumnForMenu();
    // 获取上一个月
    this.getMonth();
    // 获取门店列表
    this.getMerchantList();
    // 获取仓库列表
    this.getWarehouseList();
    // 获取品类列表
    this.getAllGoodsType();
    // 获取材质成色列表
    this.getMaterialList();
    // 获取数据
    this.getGoodsData();
    // 获取列表配置
    // this.tableConf();
  },
  methods: {
    // 取值
    getValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
      this.valueId = value;
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq()
        .then((res) => {
          if (res.code === 1) {
            this.materialList = res.data;
          }
        })
    },
    // 获取品类列表
    getAllGoodsType() {
      getAllGoodsTypeReq()
        .then((res) => {
          if (res.code === 1) {
            this.treeData = res.data;
          }
        })
    },
    // 获取仓库列表
    getWarehouseList() {
      getWarehouseListReq({
        page: 1,
        limit: 9999,
      })
        .then((res) => {
          if (res.code === 1) {
            this.warehouseList = res.data.list;
          }
        });
    },
    // 获取tab
    getColumnForMenu() {
      this.tabLoading = true;
      getColumnForMenuReq({
        menu: 'standing_recycle_column',
      })
        .then((res) => {
          if (res.code === 1) {
            this.tabLoading = false;
            this.tabs = res.data;
          }
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportReq({
        ...this.form,
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          const a = document.createElement("a");
          a.setAttribute("download", `旧料进销存列表${time}.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleColumns() {
      this.tableConf();
    },
    handleReset() {
      // 获取上一个月
      // this.getMonth();
      this.form = {
        create_time: this.create_time,
        hierarchy_id: this.hierarchy_id,
        list_type: '10',
        goods_type_id: '',
        warehouse_id: '',
        material_id: '',
      };
      this.valueId = '';
      this.getGoodsData();
    },
    handleClickTabs() {
      const [currTab] = this.tabs.filter((item) => {
        return item.field_value === this.form.list_type;
      })
      this.$set(this.columns, 1, {
          field_text: currTab.field_text,
          field_alias: "field_value",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        })
      this.getGoodsData();
    },
    handleSearch() {
      this.getGoodsData();
    },
    handleSuccess() {
      this.tableConf();
    },
    handleIndex({ row, rowIndex }) {
      if (!row.index) {
        row.index = rowIndex + 1;
      }
    },
    // 获取对账数据
    getGoodsData() {
      this.loading = true;
      getGoodsDataReq({
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // 合计
            const sum = { index: '合计', };
            this.columns.forEach((column) => {
                column.children.forEach((data) => {
                  res.data.forEach((item) => {
                  if (!sum[data.field_alias]) {
                    sum[data.field_alias] = 0;
                  }
                  if (data.type === 'total_weight' || data.type === 'total_gold_weight') {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0.0000');
                  } else if (data.type === 'total_num') {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0');
                  } else {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0.00');
                  }
                })
              })
            })
            res.data.push(sum);
            this.tableData = res.data;
            this.$nextTick(() => {
              this.$refs.dataTable.doLayout();
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.tableData = [];
        });
    },
    // 获取上一个月的天数
    getMonth() {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth() + 1;

      const form = { ...this.form };
      // 如果tMonth不是1月份；
      if (tMonth !== 1) {
        const days = new Date(tYear, tMonth - 1, 0).getDate();
        if (tMonth > 10) {
          form.create_time[0] = `${tYear}-${tMonth - 1}-01`;
          form.create_time[1] = `${tYear}-${tMonth - 1}-${days}`;
          return;
        }
        form.create_time[0] = `${tYear}-0${tMonth - 1}-01`;
        form.create_time[1] = `${tYear}-0${tMonth - 1}-${days}`;
      } else {
        const days = new Date(tYear - 1, 12, 0).getDate();
        form.create_time[0] = `${tYear - 1}-12-01`;
        form.create_time[1] = `${tYear - 1}-12-${days}`;
      }
      this.create_time = form.create_time;
      this.form = form;
    },
    // 获取列表配置
    tableConf() {
      tableConfReq('standing_book')
        .then((res) => {
          if (res.code === 1) {
            this.listConf = res.data;
            this.listConf.forEach((list) => {
              // 未使用需要隐藏列
              this.columns.forEach((item) => {
                item.children.forEach((data) => {
                  if (!list.t_is_use) {
                    if (list.field_alias === data.type) {
                      data.show = false;
                    }
                  } else {
                    if (list.field_alias === data.type) {
                      data.show = true;
                    }
                  }
                });
              });
            });
            this.getGoodsData();
          }
        });
    },
    // 获取仓库列表
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          if (res.data.length) {
            const form = {
              ...this.form,
              hierarchy_id: res.data[0].hierarchy_id,
            };
            this.hierarchy_id = res.data[0].hierarchy_id;
            this.form = form;
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleConf() {
      this.title = "列表配置";
      this.dialogVisible = true;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getGoodsData();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  flex: 1;
}
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
.box {
  // padding: 0 20px;
  padding: 0 20px;
  background: white;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  // padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
}
/deep/ .el-tabs__item {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
button {
  height: 40px;
}
.left {
  display: flex;
}
.right {
  .line {
    margin: 0 20px;
    width: 1px;
    height: 40px;
    background: #e9e9e9;
  }
}
</style>
