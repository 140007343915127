import { post } from '@/utils/request';
// 获取材质成色
const getMaterialListReq = () => post({
  url: '/store/common.Select/getMaterialList2',
});

// 获取品类列表
const getAllGoodsTypeReq = () => post({
  url: '/store/common.Select/getAllGoodsType',
});

const tableConfReq = (function_code, tab, mode) => post({
  url: '/store/common.Column/index',
  data: {
    function_code,
    tab,
    mode,
  },
});

const tableConfSet = ({ function_code, data, tab, mode }) => post({
  url: '/store/common.Column/set',
  data: {
    function_code,
    data,
    tab,
    mode,
  },
});

// 恢复默认
const restoreReq = (function_code) => post({
  url: '/store/common.Column/restore',
  data: {
    function_code
  },
});

// 获取台账数据
const getGoodsDataReq = (data) => post({
  url: '/store/statistical.StandingBook/goodsDataRecycle',
  data: {
    ...data,
  },
});

// 导出
const exportReq = (data) => post({
  url: '/store/statistical.StandingBook/goodsDataRecycleDownload',
  responseType: 'blob',
  data: {
    ...data,
  },
});

// 获取tabs数据
const getColumnForMenuReq = (data) => post({
  url: '/store/common.Select/getColumnForMenu',
  data: {
    ...data,
  },
});


export {
  getMaterialListReq,
  getAllGoodsTypeReq,
  getColumnForMenuReq,
  tableConfReq,
  tableConfSet,
  restoreReq,
  getGoodsDataReq,
  exportReq,
};
